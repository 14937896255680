import HelmetHelper from '@components/HelmetHelper'
import React from 'react'
import Layout from '@components/Layout'
import { Config, HelpCategories, Routes } from '@constants'
import { Link } from 'gatsby'

const HelpIndex = () => {
  return (
    <Layout disableContainer disableContainerBody disableLinks>
      <HelmetHelper
        title={`Help Center | ${Config.NAME}`}
        description={`${Config.NAME} support is here to help you with any problems. Reach out via chat or find articles on how to run your outreach campaigns.`}
      />
      <div className="bg-gray-100 border-b border-gray-200 -mb-8 px-6 lg:px-0">
        <div className="mx-auto max-w-screen-lg pt-16 pb-14">
          <div className="py-8 text-center">
            <h1 className="text-3xl sm:text-3xl md:text-4xl font-bold leading-normal mt-10">
              Advice and answers from {Config.NAME} Team
            </h1>
            {/* <div>Search Field</div> */}
          </div>
          <div className="flex flex-col gap-4 mt-8 mb-20">
            {HelpCategories.map(({ slug, name, description, icon: Icon }) => (
              <Link key={slug} to={Routes.HELP.category(slug)}>
                <div className="py-4 px-6 md:py-8 md:px-12 border border-gray-300 rounded flex items-center gap-4 md:gap-10 shadow hover:shadow-xl hover:cursor-pointer transition duration-150 bg-white">
                  <Icon className="w-10 h-10 text-gray-500" />
                  <div className="flex flex-col gap-1">
                    <div className="text-lg md:text-xl text-link capitalize">
                      {name}
                    </div>
                    <div className="text-xs md:text-md text-gray-600">
                      {description}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HelpIndex
